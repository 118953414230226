import React from "react"
import { FaUser, FaUsers } from 'react-icons/fa'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"

const VisitorPage = () => (
    <Layout>
        <SEO title="Registration" />
        <div className={"card-list"}>
            <Card title={"Individual"} link={""}/>
            <Card title={"Group"} link={"group"}/>
        </div>
    </Layout>
)

export default VisitorPage
